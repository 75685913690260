import Vue from "vue";

/*TITLE*/
document.title = "Marina Homes | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Marina Homes";
Vue.prototype.$subtitle = "Chalets con vistas al mar Mediterráneo";

/*INTRO*/
Vue.prototype.$promoter = "Marina Homes";
Vue.prototype.$introSubtitle = "Chalets con vistas al mar Mediterráneo";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-bottegacaliza-60x60--20230116080143.jpg";
Vue.prototype.$image_kitchen = "formato-3d-acabados--20230116080132.jpg";
Vue.prototype.$image_bath1 = "b1-bottegacaliza-60x60-bottegacaliza-33x59-marbella-stone-33x59--20230116080114.jpg";
Vue.prototype.$image_bath2 = "b2-bottegacaliza-60x60-bottegacaliza-33x59-limit-vela-33x59--20230116080121.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "adosadosbealmadenaterraza--20230116080123.jpg";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-bottegacaliza-60x60--20230116080143.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "formato-3d-acabados--20230116080132.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "b1-bottegacaliza-60x60-bottegacaliza-33x59-marbella-stone-33x59--20230116080114.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "b2-bottegacaliza-60x60-bottegacaliza-33x59-limit-vela-33x59--20230116080121.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
  {
    src: "adosadosbealmadenaterraza--20230116080123.jpg",
  },
  {
    src: "adosadosbenalmadenacam1--20230116080130.jpg",
  },
  {
    src: "adosadosbenalmadenacam2--20230116080139.jpg",
  },
  {
    src: "adosadosbenalmadenacam3--20230116080135.jpg",
  },
  {
    src: "adosadosbenalmadenacam4--20230116080147.jpg",
  },
  {
    src: "adosadosbenalmadenacam5--20230116080157.jpg",
  },
  {
    src: "adosadosbenalmadenacam6--20230116080107.jpg",
  },
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20firmamento%204%20málaga%2029631",
    text: "Calle Firmamento 4 , Málaga, 29631",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20firmamento%204%20málaga%2029631",
    text: "Calle Firmamento 4 , Málaga, 29631",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:952562867",
    text: "952562867",
  },
  {
    icon: "mdi-email",
    link: "mailto:malaga@rossoinmobiliaria.com",
    text: "malaga@rossoinmobiliaria.com",
  },
];
